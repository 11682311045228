.cls-1,
.cls-2 {
  fill: none;
}
.cls-1 {
  stroke: #292828;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}
/*# sourceMappingURL=src/oapps/commons/pictos/air.css.map */